.container-footer{
    display: flex;
	width: 90%;
	align-items: center;
    gap: 15rem;
    align-self: center;
    border-top: solid 1px #b8b8b885;
    margin-top: 5rem;
    padding-top: 3rem;
}
.logo-picture{
    display: flex;
    width: 10rem
}
.bottom-footer{
    display: flex;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
}
.footer-main{
    display: flex;
    width: 60%;
    justify-content: space-between;
}
.footer-section{
    width: 12rem;
}
.title-footer-section{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.1rem;
    padding-bottom: 1rem;
}
.footer-section-links{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.social-media-links{
    display: flex;
    gap: 1.5rem;
}