.user-wish-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 3rem;
}
.user-wish-items-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    max-height: 72vh;
    overflow-y: scroll;
    scrollbar-width: none;
    row-gap: 1rem;
}