.about-section-container{
    width: 100%;
    background: #190425;
    padding-bottom: 2vw;
}

/* VIDEO SECTION */
.about-video-container{
    width: 100%;
    padding-bottom: 2vw;
}
.video-about-us {
    position: relative;
    top: -12vh;
    width: 100%;
    height: 102vh;
    object-fit: cover;
}
.video-overlay {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100%;
}
.video-overlay h1 {
    font-family: 'Montserrat';
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 1vw;
    color: #05A2BC;
}
.video-overlay p {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 4rem;
    letter-spacing: 0.2rem;
}

/* HISTORY SECTION */
.about-history-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5vw;
    padding: 10vh 0 30vh 0;
}
.about-history-container h2{
    color: #fff;
    font-size: 4rem;
}
.about-history-container p{
    width: 80%;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    line-height: 1.6;
}

/* ADVERTISMENT IMAGES SECTION */
.about-adv-container{
    display: flex;
    width: 100%;
    padding: 0 5vw;
    gap: 1vw;
}
.pictures-adv-container{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
.pic-adv-row-1{
    display: flex;
    gap: 1vw;
}
.pic-adv-col-1{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
.pic-adv-row-2{
    display: flex;
    gap: 1vw;
}
.pic-adv-row-3{
    display: flex;
    gap: 1vw;
}
.pic-adv-1{
    width: 29vw;
    height: 15vw;
    overflow: hidden;
    border-radius: .5vw;
}
.pic-adv-1 img,
.pic-adv-2 img,
.pic-adv-3 img,
.pic-adv-4 img,
.pic-adv-5 img,
.pic-adv-6 img,
.pic-adv-7 img{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pic-adv-2{
    width: 14vw;
    height: 14vw;
    overflow: hidden;
    border-radius: .5vw;
}
.pic-adv-3{
    width: 14vw;
    height: 14vw;
    overflow: hidden;
    border-radius: .5vw;
}
.pic-adv-4{
    width: 30vw;
    height: 30vw;
    overflow: hidden;
    border-radius: .5vw;
}
.pic-adv-5{
    width: 23vw;
    height: 12vw;
    overflow: hidden;
    border-radius: .5vw;
}
.pic-adv-6{
    width: 12vw;
    height: 12vw;
    overflow: hidden;
    border-radius: .5vw;
}
.pic-adv-7{
    width: 23vw;
    height: 12vw;
    overflow: hidden;
    border-radius: .5vw;
}
.text-adv-container{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    color: #fff;
    font-size: 1.4rem;
}
.text-adv-title p{
    color: #F0E82C;
    font-size: 2rem;
}
.text-adv-title h1{
    color: #fff;
    font-size: 3.5rem;
}

/* BRANDS SECTION */
.about-brand-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #fff;
    padding: 30vh 0 20vh 0;
}
.about-brand-container h1{
    font-family: "Montserrat";
    font-size: 2vw;
    font-weight: 800;
}
.about-brand-container p{
    font-size: 2vw;
    font-family: "Montserrat";
    font-weight: 800;
}
.about-brand-images{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vh 0;
}
.about-images-row{
    display: flex;
    align-items: center;
    gap: 5vw;
}
.about-image-brand{
    width: 10vw;
    height: auto;
}