.userSideBar-container{
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    width: 23vw;
    background-color: #190425;
    align-items: center;
    padding: 1vw;
    border-radius: .8rem;
}
#userSideBar-link{
    display: flex;
    color: black;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 700;
    width: 21vw;
    height: 4.3vw;
    border-radius: .8rem;
    align-items: center;
    justify-content: center;
}