.inicio-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.landing-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.section1-inicio{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.direct-access-container{
    display: flex;
    width: 90%;
    justify-content: space-between;
    padding: 2rem 0;
}
.direct-access{
    display: flex;
    border-radius: 1rem;
    width: 12rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 1rem;
    font-weight: 500;
    gap: 1rem;
    cursor: pointer;
}
.direct-access i{
    font-size: 1.7rem;
    color: #006eb8;
}
.direct-access h2:hover{
    color: #006eb8;
}
.direct-access:hover{
    border: solid 1px #006eb8;
}
.banner-container{
    display: flex;
    align-items: center;
    width: 90%;
    height: 35vw;
    border-radius: 1rem;
    margin-bottom: 3rem;
    overflow: hidden;
}
.section-container{
    width: 100%;
    margin-bottom: 5rem;
}
.section-container ul{
    display: flex;
    list-style: none;
    width: 100%;
    justify-content: space-between;
    overflow-x: scroll;
    gap: 1rem;
    padding-bottom: 1rem;
}
.section-inside{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section-title{
    color: rgb(255, 255, 255);
    font-size: 1rem;
}
.adv-container{
    display: flex;
    width: 100%;
    justify-content: center;
}
.adv-inside{
    display: flex;
    width: 90%;
    gap: 2rem;
}
.big-adv{
    display: flex;
    width: 50rem;
    height: 30rem;
    border-radius: 2rem;
    overflow: hidden;
}
.sb-column{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.sb-row{
    display: flex;
    gap: 1rem;
}
.small-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19.5rem;
    height: 14.5rem;
    border-radius: 2rem;
    overflow: hidden;
}
/* .gamer-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    gap: 1rem;
}
.work-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    gap: 1rem;
}
.builds-title{
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 1.8rem;
    font-family: 'Roboto';
    font-weight: 500;
    align-items: center;
}
.builds-title button{
    display: flex;
    color: #fff;
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-weight: 500;
    background: #190425;
    padding: 1rem 4rem;
    border: none;
    border-radius: 1rem;
    align-items: center;
    cursor: pointer;
}
.builds-destacados{
    display: flex;
    list-style: none;
    overflow-x: scroll;
    gap: 1rem;
    padding-bottom: 1rem;
}
.build-destacado{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 1rem;
    background-color: #fff;
    flex: 0 0 auto;
    width: 23rem;
}
.img-build-container{
    width: 10rem;
}
.text-build-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    align-items: center;
    font-family: 'Montserrat';
}
.text-build-container h2{
    font-size: 1.5rem;
    font-weight: 800;
}
.text-build-container p{
    align-self: flex-start;
    font-family: 'Roboto';
}
.text-build-container strong{
    font-size: 2rem;
    color: #2D0346;
    font-weight: 900;
}
.details-build-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    gap: 1vw;
    padding-top: 1vw;
}
.build-component-container{
    display: flex;
    gap: 1vw;
}
.img-build-component{
    display: flex;
    width: 2.5vw;
    height: 2.5vw;
}
.component-title{
    width: 15vw;
}
.component-title strong{
    color: black;
    font-size: 1.3rem;
    font-weight: 550;
    font-family: 'Roboto';
}
.build-destacado button{
    background-color: #009317;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
} */
.relevant-products::-webkit-scrollbar,
.builds-destacados::-webkit-scrollbar,
.section-container ul::-webkit-scrollbar{
    height: 0.8rem;
}
.relevant-products::-webkit-scrollbar-track,
.builds-destacados::-webkit-scrollbar-track,
.section-container ul::-webkit-scrollbar-track{
    background-color: #F5F5F5;
    border-radius: 1rem;
}
.relevant-products::-webkit-scrollbar-thumb,
.builds-destacados::-webkit-scrollbar-thumb,
.section-container ul::-webkit-scrollbar-thumb{
    background-color: #006eb8;
    border-radius: 1rem;
}

