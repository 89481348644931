.container-product{
    display: flex;
    flex-direction: column;
    gap: 2vw;
    flex-grow: 1;
    padding-bottom: 2vw;
}
.container-product-body{
    display: flex;
    flex-direction: column;
    gap: 1%;
    padding: 0 1vw;
}
.container-side{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.select-container {
    appearance: none;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    color: #000000;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }