.relevant-products-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
    padding-top: 5rem;
    gap: 2rem;
}
.relevant-products{
    display: flex;
    list-style: none;
    overflow-x: scroll;
    gap: 1.5rem;
    padding-bottom: 1rem;
    border-top: solid 1px #b8b8b885;
    padding-top: 2rem;
}
.relevant-product{
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    flex: 0 0 auto;
    width: 15rem;
    justify-content: space-between;
    gap: 2rem;
}
.img-product-container{
    display: flex;
    align-self: center;
    width: 8rem;
}
.text-product-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 12rem;
    align-self: flex-end;
    justify-content: space-between;
}
.name-product-container{
    display: flex;
    align-self: center;
    width: 90%;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: .95rem;
}
.price-product-container{
    display: flex;
    flex-direction: column;
    padding: 5% 5%;
    gap: 1rem;
}
.price-product-container button{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.3rem;
    border: solid 1px #006eb8;
    border-radius: 0.3rem;
    cursor: pointer;
    width: 100%;
}