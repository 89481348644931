.user-cart-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 3rem;
}
.user-section-title{
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 1.2rem;
}
.user-cart-items-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 75vh;
    overflow-y: scroll;
}
.user-cart-items-container::-webkit-scrollbar{
    width: 0;
}
.user-item-shopCart{
    list-style: none;
    display: flex;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.205);
    border-radius: 1rem;
    padding: 1rem;
    gap: 1rem;
}
.user-image-item{
    width: 7vw;
    height: 7vw;
    border-radius: 1rem;
    overflow: hidden;
}
.user-text-item{
    display: flex;
    width: 90%;
    justify-content: space-between;
}
.user-text-body{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.user-count-item{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.user-price-item{
    align-self: center;
    font-size: 1.2rem;
    font-weight: 700;
}
.user-total-cart-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.user-total-cart-rows{
    display: flex;
    flex-direction: column;
    width: 70%;
}
.user-total-cart-row1{
    display: flex;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    justify-content: space-between;
}
.user-total-cart-container button{
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
}