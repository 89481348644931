.user-addresses-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 3rem;
}
.user-addresses-tittle{
    color: #fff;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    font-weight: 900;
}
.address-item-container{
    list-style: none;
    display: flex;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.205);
    border-radius: 1rem;
    padding: 1rem;
}
.check-circle{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: solid 2px #000;
    background-color: #00E425;
}
.uncheck-circle{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: solid 2px #000;
    background-color: rgb(255, 255, 255);
}
.address-text-rows{
    width: 100%;
}
.address-text-row-city{
    display: flex;
}
.address-text-row{
    display: flex;
    padding: 0.5rem 0;
}
.address-text-title{
    font-family: 'Montserrat';
    font-weight: 400;
    width: 7rem;
}
.address-text-data{
    font-family: 'Montserrat';
    font-weight: 700;
}