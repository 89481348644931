.user-orders-container{
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 3rem 0 0 0;
    gap: 1rem;
}
.user-orders{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 75vh;
    overflow-y: scroll;
    scrollbar-width: none;
}
.user-order{
    /* display: flex; */
    border: none;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.205);
    border-radius: 1rem;
    padding: 1rem;
    height: 8rem;
    cursor: pointer;
}
.user-order-data{
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    width: 90%;
    justify-content: space-between;
}
.user-order-text{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 1rem;
}
.user-order-text strong{
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 700;
}
.user-order-description{
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    font-size: 1rem;
}
.orden-cancelada{
    align-self: center;
    color: #D00000;
    font-weight: 700;
}
.orden-pendiente{
    align-self: center;
    color: #C66E07;
    font-weight: 700;
}
.orden-entregada{
    align-self: center;
    color: #006eb8;
    font-weight: 700;
}
.user-order-data button{
    width: fit-content;
    background: transparent;
    border: none;
    color: #D00000;
}
.user-order-panel{
    display: flex;
    flex-direction: column;
    box-shadow: 5rem 5rem 5rem rgb(0, 0, 0);
}
.user-order-details-container{
    display: flex;
    justify-content: space-between;
}