.user-main-container{
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
}
.user-main-container-col1{
    width: 30%;
    align-self: center;
}
.user-main-container-col2{
    width: 60%;
    /* max-height: 100%; */
}
