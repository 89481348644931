.header {
    transition: transform 0.3s ease-in-out;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
}
.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 0.5rem 5rem;
    border-bottom: solid 1px #b8b8b885;
}
.home-container{
    font-size: 1.5rem;
    display: flex;
    color: aliceblue;
    gap: 1.5rem;
    align-items: center;
}
.logo-container{
    width: 12rem;
}
.search-container{
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    padding: .5rem 1rem;
    height: fit-content;
}
.search-container form{
    display: flex;
    gap: 1rem;
}
.search-container button{
    border: none;
    height: fit-content;
    width: fit-content;
    background-color: transparent;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
}
.search-container input{
    border: none;
    font-size: 1.5rem;
    width: 20vw;
}
.bar-container{
    display: flex;
    gap: 2rem;
}
.bar-container i{
    font-size: 2rem;
}
.login{
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Montserrat';
}