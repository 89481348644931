.container-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.container-prod-main{
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Define 3 columnas de igual ancho */
    row-gap: 1vw; /* Espaciado entre los elementos (opcional) */
    column-gap: 2vw;
}
.item-prod-main{
    display: flex;
    flex-direction: column;
    width: 18vw;
    height: 35vh;
    border-radius: 10px;
    justify-content: space-between;
}
.main-fav-star{
    display: flex;
    justify-content: right;
    position: relative;
    top: 0.5rem;
    right: 0.5rem;
}
.item-image-main{
    display: flex;
    align-self: center;
    width: 60%;
    height: 80%;
}
.item-text{
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}
.item-name{
    align-self: center;
    display: flex;
    width: 90%;
}
.item-box{
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
    font-size: 1.05vw;
}
.verify-cart{
    color: #009317;
    font-weight: 700;
}
.item-box button{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.3rem;
    border: none;
    border-radius: 0.3rem;
    align-items: center;
    cursor: pointer;
}
.item-text p{
    color: rgb(0, 0, 0);
}
.arrow-prod-main{
    display: flex;
    gap: 0.5rem;
    color: #aaa;
}
