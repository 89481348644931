
.gamer-container{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 90%;
    margin-top: 5rem;
    gap: 1rem;
}
.work-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    gap: 1rem;
}
.builds-title{
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 1.8rem;
    font-family: 'Roboto';
    font-weight: 500;
    align-items: center;
}
.builds-title button{
    display: flex;
    color: #fff;
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-weight: 500;
    background: #190425;
    padding: 1rem 4rem;
    border: none;
    border-radius: 1rem;
    align-items: center;
    cursor: pointer;
}
.builds-destacados{
    display: flex;
    list-style: none;
    overflow-x: scroll;
    gap: 1.5rem;
    padding-bottom: 1rem;
    border-top: solid 1px #b8b8b885;
    padding-top: 2rem;
}
.build-destacado{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 1rem;
    flex: 0 0 auto;
    width: 23rem;
    padding: 0.7rem;
}
.img-build-container{
    width: 10rem;
}
.text-build-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    align-items: center;
    font-family: 'Montserrat';
}
.text-build-container h2{
    font-size: 1.5rem;
    font-weight: 800;
}
.text-build-container p{
    align-self: flex-start;
    font-family: 'Roboto';
}
.text-build-container strong{
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: 600;
    color: red;
}
.details-build-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    gap: 1vw;
    padding-top: 1vw;
}
.build-component-container{
    display: flex;
    gap: 1vw;
}
.img-build-component{
    display: flex;
    width: 2.5vw;
    height: 2.5vw;
}
.component-title{
    width: 15vw;
}
.component-title strong{
    color: black;
    font-size: 1rem;
    font-weight: 550;
    font-family: 'Roboto';
}
.build-destacado button{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.3rem;
    border: solid 1px #006eb8;
    border-radius: 0.3rem;
    cursor: pointer;
    width: 100%;
}