.user-dashboard-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    gap: 2rem;
}
.user-data-dashboard-container{
    display: flex;
}
.user-avatar-dashboard-container{
    border-radius: 50%;
    overflow: hidden;
    width: 15vw;
    height: 15vw;
}
.user-icon-range{
    width: 3vw;
}
.banner-temp {
    position: absolute;
    display: flex;
    top: 70%;
    left: 63%;
    transform: translate(-50%, -50%);
    width: 40rem;
}
.user-achiev-dashboard-container{
    align-self: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.achievment-container{
    width: 14vw;
    height: 12vw;
    background: #aaa;
    border-radius: 1vw;
}