.cart-container{
    display: flex;
    flex-direction: column;
}
.cart-button{
    display: flex;
    font-size: 2rem;
    cursor: pointer;
}
.cart-number{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #006eb8;
    color: #ffffff;
    position: relative;
    z-index: 500;
    top: -10px;
    right: 5px;
    font-size: 1rem;
    font-weight: bold;
    border: #006eb8 solid 2px;
}
.shop-cart{
    position: fixed;
    right: 0px;
    top: 9vh;
    z-index: 500;
    width: 30rem;
    height: 91vh;
    padding: .5rem 1rem 2rem 1rem;
    transform: translateX(100%);
    transition: transform .5s ease-in-out;
    overflow-y: scroll;
    scrollbar-width: none;
}

.title-cart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgb(0, 0, 0);
    padding: .5rem 0;
}
.title-cart h1{
    font-size: 2rem;
    padding-left: .5rem;
}
.title-cart button{
    border: none;
    height: fit-content;
    width: fit-content;
    background-color: transparent;
    color: rgb(0, 0, 0);
    cursor: pointer;
}
.title-cart button i{
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
}
.body-cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
}
.body-cart h2{
    display: flex;
    height: 40rem;
    width: 100%;
    color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
}
.body-cart ul{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 56vh;
    overflow-y: scroll;
    scrollbar-width: none;
    gap: 3vh;
}
.item-shopCart{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.item-cart{
    display: flex;
    width: 100%;
    height: fit-content;
    gap: 1vw;
}
.image-item-cart{
    width: 8rem;
    height: 6rem;
    border-radius: 1rem;
    overflow: hidden;
}
.text-item-cart{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.text-body-cart{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: .5vh 0;
}
.text-body-cart strong{
    font-size: 1rem;
}
.price-item-total{
    display: flex;
    font-size: 1.2rem;
    font-weight: bolder;
    height: 100%;
    align-items: center;
}
.count-item-cart{
    display: flex;
    align-items: center;
    gap: 1vw;
}
.count-item-cart div{
    display: flex;
    gap: 1vw;
    align-items: center;
}
.count-item-cart button{
    display: flex;
    background-color: transparent;
    color: #474747a9;
    border: none;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.count-item-cart button i{
    font-size: 1rem;
}
.delete-button-cart{
    display: flex;
    height: 100%;
    align-items: center;
}
.delete-button-cart button{
    display: flex;
    color: rgba(255, 0, 0, 0.76);
    font-size: 1rem;
    align-items: center;
    cursor: pointer;
}
.footer-cart{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25vh;
    background-color: #ffffff;
    position: fixed;
    top: 65vh;
    border-top: solid 1px #000000;
    gap: 2vh;
    justify-content: center;
}
.footer-cart-column{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 0 1vw;
}
.footer-cart-row{
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-size: 1.2rem;
}
.footer-cart-row-total{
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    color: #000000;
}
.body-cart .send-cart{
    font-weight: 900;
    width: fit-content;
    height: fit-content;
    padding: 1rem 1rem;
    align-self: center;
}
.cart-button ~ input:checked ~ .shop-cart{
    transform: translateX(0);
}
