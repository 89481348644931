.user-helpCenter-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 3rem;
}
.user-helpCenter-section{
    display: flex;
    flex-direction: column;
    height: 75vh;
    overflow-y: scroll;
    scrollbar-width: none;
}
.user-helpCenter-section-title{
    width: 100%;
    background-color: aliceblue;
}