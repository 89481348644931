*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html{
  font-size: 1vw;
  max-width: 100vw;
  min-height: 100vh;
}
body{
  font-family: "Roboto";
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
img{
  max-width: 100%;
}
a{
	text-decoration: none;
}
a:visited{
	color: #000000;
}
a:link{
	color: #000000;
}
#reset{
  color: rgb(255, 255, 255);
}